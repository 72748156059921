import React from "react"
import Layout from "../../layouts/Layout"
import Contact from "../../components/Contact/Contact"

const ContactPage = () => {
  const metadata = {
    title: "Ponerse en contacto",
    description:
      "Conozca mi experiencia profesional en diseñador web con mis historias en la industria tecnológica.",
    cover: "/images/covers/contact_cover.png",
    lang: "es",
    type: "website",
    path: "/contact",
  }

  return (
    <Layout metadata={metadata}>
      <Contact />
    </Layout>
  )
}
export default ContactPage
